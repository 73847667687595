footer{
    /* background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  ); */
    color: #fff;
    background-color: #2ca3fa;

}

.footer-img {
    width: 100%;
}