.srv-validation-message {
    color: red
}


.MuiFormControl-root {
    margin: 10px 0px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -10px) scale(0.75) !important;
    background-color: aliceblue;
    padding: 0px 10px;
}

.MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1) !important;
}

.MuiOutlinedInput-root {
    border-radius: 14px !important;
}

.MuiOutlinedInput-input {
    padding: 10px 14px !important;
}

.card-header-h2 {
    color: rgb(103, 58, 183);
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    padding: 0 50% !important;
}

.css-igs3ac {
    padding: 0px 50% !important;
}

a {
    font-weight: 500;
    color: rgba(28, 24, 245, 0.856);
}

.linkBtn {
    color: blue;
}

.linkBtn :hover {
    color: green;
}

.accent-success {
    border-top: #198754 4px solid;
}

.accent-danger {
    border-top: #dc3545 4px solid;
}

.accent-warning {
    border-top: #ffc107 4px solid;
}

.accent-primary {
    border-top: #0d6efd 4px solid;
}

.accent-primary {
    border-top: #6c757d 4px solid;
}

.accent-dark {
    border-top: #212529 4px solid;
}

.accent-info {
    border-top: #0dcaf0 4px solid;
}