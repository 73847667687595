*{
  margin:0;
  padding: 0;
}



body {
  font-family: 'Poppins', 'Roboto', 'Roboto Flex', sans-serif;
}

code {
  font-family: 'Poppins', 'Roboto', 'Roboto Flex', sans-serif;
}
