.nav1 {
  position: relative;
  top: 0;
  z-index: 10;
}

.navbar {
  /* background-image: linear-gradient(to right, #a517ba, #5f1782); */
  /* background-color: #4158d0; */
  /* background-image: linear-gradient(43deg,

      #4158d0 0%,
      #c850c0 46%,
      #ffcc70 100%); */
  padding: 0 !important;
  background-color: #2ca3fa;
  align-items: center;
}

.navbar-brand img {
  /* height: 40px; */
  padding-left: 0px;
  width: 500px;
}

.navbar-logo img {
  height: 40px;
  padding-right: 0px;
}

.navbar-nav li {
  padding: 0 10px;
}

.navbar-nav li a {
  color: #fff !important;
  font-weight: 600;
  float: right;
  text-align: left;
  text-decoration: none;
}

.navbar-nav li div {
  float: right !important;
}

.navbar-toggler {
  float: right;
  outline: none !important;
  padding: 0% !important;

}

@media only screen and (max-width: 767px) {
  .css-5nwj3y {
    float: right !important;
  }

  .navbar-brand img {
    height: 40px;
    padding-left: 0px;
    width: 285px;
  }
}