.subheading {
    margin-left: 0% !important;
}

.questions {
    color: rgb(103, 58, 183) !important;
    font-size: large !important;
}

.stepperBtn {
    background-color: rgb(103, 58, 183) !important;
    color: #FFFF;
    /* font-weight: ; */
    border-radius: 0.5rem !important;
}

.draftBtn {
    background-color: #07bc0c !important;
    color: #FFFF;
    /* font-weight: ; */
    border-radius: 0.5rem !important;
}

.levelthreemargin {
    margin-left: 3% !important;
}

@media only screen and (max-width: 767px) {
    .subheading {
        margin-left: 5% !important;
    }

    .levelthreemargin {
        margin-left: 6% !important;
    }
}


.css-1ujnqem-MuiTabs-root {
    padding: 5px 0px;
}

.patientVisitTopTabPanels {
    min-height: 63vh;
    max-height: 63vh;
    overflow-y: auto;
}

.css-1wcaknn {
    padding: 10px 5px !important;
}

.patientVisitTopTabs button {
    border-radius: 10px;
    margin: 2px;
    font-family: Arial, Helvetica, sans-serif;

    color: #fff;
    border: 1px solid transparent;
    background-color: #38A4CA;
}

.patientVisitTopTabs button.Mui-selected {
    color: #2600b7;
    background-color: #43D4FF;
    font-weight: bolder;
}

#pdf-download {
    display: none;
}

.react-pdf__Page__textContent {
    color: black !important;
}
.header-logo{
    width:15% !important;
    height:auto !important;
}
.app-footer{
    position: fixed;
    width: 100%;
    bottom: 0;
}
.card-list-header{
    font-size: calc(0.6rem + .9vw);
}
.bodyContainer{
    margin-bottom: 50px;
}
.button-rating_group{
    margin: 5px;
}
.video-player{
    display: flex;
}
.video-list-card{
    margin: 5px;
    cursor: pointer;
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline
{
     pointer-events: none;
}
audio::-moz-media-controls-timeline,
video::-moz-media-controls-timeline
{
    pointer-events: none;
}
.custom-button {
    text-decoration: none;
    background: #0a58ca;
    padding: 6px;
    border-radius: 0.2rem;
    color: #fff !important;
    font-size: .875rem;
    font-family: inherit;
}
.custom-button a:hover{
    color: #fff !important;
}
.modal_header_footer{
    background-color: #d1dde9;
}
.accordion-button{
    /* background-color: #d4b298; */
    background-color: #e8d1c5;
}
.accordion-button:not(.collapsed){
    background-color: #f3d275;
    color: blue;
}
.video_player{
    height: auto;
    width: 100%;
}